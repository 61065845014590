<template>
  <div
    :class="{
      'site-header-container': true,
      'mobile-menu-active': showMobileMenu,
    }"
  >
    <a href="#main" class="skip-link">Skip to main content.</a>
    <!-- Render promo banner as client-only to avoid it being cached -->
    <client-only>
      <PromoBannerNew />
      <ProductEditBanner
        :user="$auth.user"
        :route="$route"
        :product="$store.getters.productObject"
      />
      <OccasionEditBanner
        :user="$auth.user"
        :route="$route"
        :occasion="$store.getters.thisOccasion"
      />
    </client-only>
    <header id="site-header">
      <UtilityNavigation
        @activate-mobile-menu="$store.dispatch('setShowMobileMenu', true)"
      />
      <div class="rule"></div>
      <SiteNavigation
        @close-mobile-menu="$store.dispatch('setShowMobileMenu', false)"
      />
      <MobileSiteNavigation />
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SiteHeader',
  components: {
    PromoBannerNew: () => import('~/components/Header/PromoBannerNew'),
    ProductEditBanner: () => import('~/components/Header/ProductEditBanner'),
    OccasionEditBanner: () => import('~/components/Header/OccasionEditBanner'),
    UtilityNavigation: () => import('~/components/Header/UtilityNavigation'),
    SiteNavigation: () => import('~/components/Header/SiteNavigation'),
    MobileSiteNavigation: () =>
      import('~/components/Header/MobileSiteNavigation'),
  },
  computed: {
    ...mapGetters(['showMobileMenu']),
  },
}
</script>
